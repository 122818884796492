<template>
  <div class="main">
    <div class="top">
      <div class="back" @click="onClickLeft('/')">
        <img src="../assets/img/back.png" alt="" />
      </div>
      <div class="title">
       {{$t('m.sbzl')}}
      </div>
      <div class="navbtn" @click="onClickLeft('/mynft')">
       {{$t('m.wdnft')}}
      </div>
    </div>
    <div class="card fadeInUp">
   <div class="tops">
            <img src="../assets/img/nftimg.jpg" alt="" class="nft">      
            
        </div>
      <!-- <div class="yqlink">
        <div class="ltxt">{{$t('m.xzrged')}}</div>
      </div> -->
     
      <div class="bottom">
        <van-button
          :loading="loading"
          type="primary"
          @click="menu()"
          class="btn"
          > {{$t('m.qrcy')}}</van-button
        >
      </div>
    </div>
  

    <van-popup v-model="menuShow" round :close-on-click-overlay="!loading" >
      <div class="popup">
        <!-- <div class="title">{{$t('m.zfqr')}}</div> -->
        <!-- <div class="xztype">        
       <div class="item">{{$t('m.rgsl')}}：{{(value).toFixed(0)}}</div>
         <div class="item">{{$t('m.zfje')}}：{{(value*1000).toFixed(0)}}USDT</div>        
        </div>         -->
        <div class="bg flex">       
          <div class="tips act"  >
          <div class="stop">
          <!-- <img src="../assets/img/hy.png" alt="" class="imgs" /> -->
          <div class="">MASTER NFT</div>
          </div>
          <div class="sbottom fadeInUp" >
            <div class="lines"><span>{{$t('m.zldj')}}</span>  <span>$1000</span> </div>
           
            <div class="lines"><span>{{$t('m.rgsl')}}</span>  <span>+{{value}}</span> </div>

             <!-- <div class="lines"><span>{{$t('m.zfje')}}</span>  <span>${{1000*value}}</span> </div>  -->
             <div class="lines"><span>{{$t('m.qbye')}}</span>  <span>${{myAmount}}</span> </div> 
            
        
              <div class="box">
                 <img :src="dqyy =='en-US'?qydata.thumb_en: qydata.thumb" alt="" class="imgss" />
                <!-- <div class="txt">{{ $t('m.qy1') }}</div>
                <div class="txt">{{ $t('m.qy2') }}</div>
                <div class="txt">{{ $t('m.qy3') }}</div>
                <div class="txt">{{ $t('m.qy4') }}</div> -->
              </div>
          </div>
        </div>        
      </div> 
        <van-button
          :loading="loading"
          type="primary"
          @click="toJoin"
          class="btn"
          :loading-text="$t('m.zfload')"
        >
          {{ $t("m.qdzf") }}</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { upnode,getMyUsdt} from "@/api/Public";
export default {
  data() {
    return {
      userWallet: "",
      codeWallet: "",
      userInfo:'',
      list: [],
      day:10,
      total: 0,
      page: 1,
      loading: false,
      finished: false,
      refreshing: false,
      menuShow: false,
      pType: 0,
      type: '',
      ptype:1,
      myAmount:0,
      Amount: {
        usdt:0
      },
      price: 0,
      bsn: "",
      bk: "",
      cake: "",
      base: "",
      fate:0,
      value:1,
      gpList:[
        {name:'$200',
        usdtAmout:200,
        id:1,
        stock:20,
        stock1:39,
        stock2:99,
        day:2,
        day1:1.3,
        day2:1.1
        },
         {name:'$500',
         usdtAmout:500,
        id:2,
        stock:21,
         stock1:42,
        stock2:108,
        day:2.1,
        day1:1.4,
        day2:1.2
        },
         {name:'$1000',
         usdtAmout:1000,
        id:3,
        stock:23,
         stock1:45,
        stock2:117,
        day:2.3,
        day1:1.5,
        day2:1.3
        },
         {name:'$3000',
         usdtAmout:3000,
        id:4,
        stock:25,
         stock1:48,
        stock2:126,
        day:2.4,
        day1:1.6,
        day2:1.4
        }
      ],
      info:0,
      teamRadio:0,
      pAmount:'',
      qydata:{
        thumb:require('../assets/img/qy.jpg'),
        thumb_en:require('../assets/img/qy1.jpg')
      }
    };
  },
  computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "zh-CN";
      }
    },
  },
  filters: {
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{8})\w+(\w{8})/, "$1...$2");
        return a;
      } else {
        return "";
      }
    },
  },
  watch: {
    "$store.getters.userWallet": function (val) {
      this.onClickLeft("/");
    },
    deep: true,
    immediate: true,
  },
  created() {
    this.userWallet = this.$store.getters.userWallet;
    // this.getBase()
    // this.getlimt();
    this.getFate()
    this.getlist();
  },
  methods: {
    getFate(){
      getMyUsdt(this.$store.getters.userWallet).then(res=>{
        console.log(res);
        this.myAmount=res
      })
    },
      topType(e){
          if(!this.loading){
          this.ptype=e}},
    getBase(){
       this.$get({
        url: "wms-wallet/getWallet",
        data:{ trxAddress:this.userWallet},
        callback: (res) => {
          this.$nextTick(function () {
              this.userInfo=res.data
          })
        }
       })
       this.$get({
        url: "wms-wallet/getMinTeam",
        data:{ uid:this.$store.getters.myID},
        callback: (res) => {
          this.$nextTick(function () {
              this.teamRadio=res.data
          })
        }
       })
       
    },
    getlimt() {
      this.$get({
        url: "plat-history/getDay",
        data: { uid: this.$store.getters.myID },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.base = res.data;
            }
          });
        },
      });
    },
    async getlist(){
      
     
      
    },
    async toType(e) {
      this.type = e.usdt;
      //  this.$get({
      //   url: "ums-product/getList1",
      //   data: {price:e.usdt },
      //   callback: (res) => {
      //     this.$nextTick(function () {
      //       if (res.data) {
      //         this.gpList = res.data;
      //         this.type = e.usdt;
      //         this.Amount=e
      //       }
      //     });
      //   },
      // });
      
    },
    async toMax() {
      if (this.loading) {
        return;
      }
      await this.getlimt();
      this.Amount = this.base.info3;
    },
    js() {
      setTimeout(() => {
        if (this.base.info3 > 0) {
          this.Amount =
            this.Amount > this.base.info3 ? this.base.info3 : this.Amount;
        } else {
          this.Amount = 0;
        }
      }, 100);
    },

    async getPrice() {
      this.loading = true;
      this.$get({
        url: "sms-base/getRate",
        data: {},
        callback: (res) => {
          this.$nextTick(function () {
            if (this.type == 1) {
              this.price = res.data.bking;
            }
            if (this.type == 2) {
              this.price = res.data.cake;
            }
            if (this.type == 3) {
              this.price = res.data.bnb;
            }
            this.loading = false;
          });
        },
      });
    },

    onClickLeft(val) {
      if (this.loading) {
        this.$notify(this.$i18n.t("m.wxtswtjxz"));
        return;
      } else {
        this.$router.replace({ path: val });
      }
    },
    menu() {
        if(this.value>0){
           this.$get({
       url: "wms-wallet/getWallet",
        data:{ trxAddress:this.userWallet},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data.userLevel<0) {
              this.menuShow = true; 
            }else{
              this.$notify(this.$i18n.t("m.nyrg"));
            }
          });
        },
      });
          
        }else{
             this.$notify(this.$i18n.t("m.qxztzed"));
        }
    },
    gbsmenu() {
      this.menuShow = false;
    },
    toJoin(){
     if(this.value>0){
        let amount=(this.value*1000).toFixed(4)
        if(amount>0){
             this.loading=true
      upnode(this.$store.getters.userWallet,amount).then(trxId=>{
        // console.log(trxId,'trxId');
        //  trxId='0xa67cbb1582b8f3c4d069ba1e82370ff665b8bf248177ec5c85fb6761556ea8be'
        if(trxId){
                 this.$post({
        url: "ums-product-history/createHistory",
        data: { uid: this.$store.getters.myID,
          p1:amount,
          t1:this.value,
          trxId:trxId,
          trxAddress:this.$store.getters.userWallet
        },
        callback: (res) => {
          this.$nextTick(function () {
            this.loading=false
            if (res.data) {
               this.$notify({
                message: this.$i18n.t("m.rgcg"),
                type: "success",
              });
              this.getFate()
              this.pAmount={}
              this.menuShow=false
            }else{
              this.$notify(this.$i18n.t("m.rgsb"))
            }
          })}
                 })
        }else{
          this.loading=false
            this.$notify(this.$i18n.t("m.qxrg"))
        }
      })
        }
     }
//        this.$get({
//         url: "plat-history/getPlatLimit",
//         data: { uid: this.$store.getters.myID },
//         callback: (res) => {
//           this.$nextTick(function () {
//             if (res.data) {
           
//         let amount=this.Amount
//         let type=this.ptype
//         if(type==1){
//     transferBks(
//                     this.$store.getters.userWallet,
//                     amount
//                   ).then((res) => {
//                     if (res) {
//                       this._pay(res, amount, type);
//                     } else {
//                       this.loading = false;
//                     }
//                   });
// }else
// if(type==3){
//    this._pay('',amount,type)
// }else
// {
//      this._pay('',amount,type)
// }
//             }else{
//               this.loading = false;
// this.$notify(this.$i18n.t("m.bmztztj"));
//             }
//           });
//         },
//       });
        


        
    },
    tochange(e){
      if(this.loading){return}
      this.pAmount=e
    },
    _pay(haxi, amount, type) {
      this.$post({
        url: "plat-history/createHistory",
        data: {
          trxAddress: this.userWallet,
          uid: this.$store.getters.myID,
          price:amount,
          type: type,
          trxId: haxi,
        },
        callback: (res) => {
          this.$nextTick(function () {
            this.loading = false;
            if (res.data) {
              this.$notify({
                message: this.$i18n.t("m.cycg"),
                type: "success",
              });
        this.menuShow=false
            } else {
              this.$notify(this.$i18n.t("m.cysb"));
            }
          });
        },
      });
    },
    goto(val) {
      this.$router.push({ path: val });
    },
    changer(val) {
      if (val == "en-US") {
        val = "zh-CN";
      } else {
        val = "en-US";
      }
      this.$i18n.locale = val;
      this.$store.commit("language", val);
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },

    onLoad() {
      if (this.page == 1) {
        this.list = [];
        this.refreshing = false;
      }

      this.$post({
        url: "user/UserReward",
        header: this.$store.getters.userWallet,
        data: {
          page: this.page,
          ftype: this.active == 0 ? "18" : this.active == 1 ? "21" : "24",
          list_rows: 10,
        },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data.data) {
              this.loading = false;
              if (this.page > 1) {
                this.list = [this.list, ...res.data.data];
              } else {
                this.list = res.data.data;
              }

              if (res.data.data.length >= 10) {
                this.page++;
              } else {
                this.finished = true;
              }
            }
          });
        },
      });
    },

    
    onCopy() {
      this.$toast("复制成功");
    },
    onError() {
      this.$toast("复制失败");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.main {
  height: 100%;
  min-height: 100vh;
  width: 750px;
  margin: 0 auto;
 background:  #161628 url("../assets/img/dsbg.jpg")  fixed no-repeat;
  // background: #0F112A;
  background-size:cover;
  background-position: top center;
  position: relative;
  ::v-deep.van-nav-bar__content {
    height: 80px !important;
  }
  ::v-deep.van-nav-bar__arrow {
    font-size: 36px;
  }
  ::v-deep.van-ellipsis {
    font-size: 32px;
    line-height: 44px;
  }
  .top {
    background: #17012fbf;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
    height: 96px;
    margin: 0 auto;
    color: #fff;
    border-bottom: 1px solid #656262;
    .back {
      position: absolute;
      left: 20px;
      width: 50px;
      height: 50px;
      //   background: linear-gradient(45deg,#e4005e, #7C58D2);
      // border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 50px;
        width: 50px;
      }
      .icosn {
        height: 36px;
        margin-right: 5px;
        width: 36px;
      }
    }
    .title {
      background: linear-gradient(234deg, #ffffff, #ffffff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-weight: 600;
      font-family: $t-f;
    }
    .navbtn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 150px;
      position: absolute;
    right: 28px;
    font-size: 24px;
        font-family: $t-mf;
        font-weight: 600;
        color: #fff;
        line-height: 42px;
        white-space: nowrap;
      .img {
        width: 36px;
        height: 30px;
      }
      .img2 {
        width: 36px;
        height: 36px;
        margin: 0 36px;
      }
      .lang {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: 600;
        color: #fff;
        line-height: 42px;
        // margin-left: 20px;
      }
    }
  }
  .card {
    width: 652px;
    // background: rgb(5 3 15) url('../assets/img/abg.png') no-repeat;
     box-shadow: 0px 8px 6px rgb(0 0 0);
    background: #17012cc4;
        background-size: 100% 100%;
    border-radius: 0px;
    padding: 20px;
    margin: 5% auto;
    // border: 1px solid #7C58D2;
    .tops{
          display: flex;
          justify-content:center;
          align-items: center;
          position: relative;
          // margin: 48px;
          width:100% ;
          text-shadow: 0px 2px 15px rgba(0,0,0);
          .nft{
            width:100%;
            height:auto;
            background: #fff6f6;
            border: 1px solid  #7C58D2;
                 box-shadow: 0px 18px 6px rgb(16 16 16 / 39%);
          }
    }
    .logo {
      width: 250px;
    }
    .yqlink {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 18px;
      .ltxt {
        font-size: 30px;
        font-family: $t-f;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        width: 100%;
        margin: 0px 24px;
      }
      .mylink {
        width: 440px;
        height: 60px;
        background: #fff;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 22px;
        .address {
          width: 250px;
          height: 32px;
          font-size: 22px;
          font-family: $t-mf;
          font-weight: 600;
          color: #000000;
          line-height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .fuzhi {
          background: transparent;
          border: none;
          width: 50px;
        }
        .icon {
          width: 42px;
          height: 42px;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      // background: #f8f8f8;
      margin: 10px 0;
    }

    .bg {
      // background: linear-gradient(234deg, #e4005e, #7C58D2);
      margin: 20px auto;
      padding: 15px;
      position: absolute;
      width: 100%;
      height: 100%;
      // box-shadow: 0 10px 5px rgb(10 10 10 / 54%);
    }
    .flex {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      .act {
        // border: 5px solid #EF155D;
        // border-radius: 10px;
        color: #ffffff;
            background: rgb(25 0 22 / 51%);
    box-shadow: 2px 2px 0.04rem 0px #131418;
        // background:#0800195e;
        //  box-shadow:  inset 2px 2px 3px 0px #131418, inset -2px -2px 3px 0px #474853;
      }
    }
    .box{
      min-height: 300px;
      width: 100%;
    
    }
    .tips {
        flex-grow: 1;
        border: 5px solid transparent;
      font-size: 30px;
      font-family: $t-f;
      font-weight: 400;
      color: #333333;
      text-align: left;
      padding: 20px;
      background: rgb(255 255 255 / 100%);
      display: flex;
      align-items: center;
      overflow: hidden;
      flex-direction: column;
      margin: 20px 0;
    width: calc(100% - 38px);
    justify-content: flex-start;
    box-sizing: border-box;
    .stop{
         display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .sbottom{
      width: 100%;
      .lines{
        margin: 20px;
          display: flex;
      align-items: center;
      justify-content: space-between;
          border-bottom: 1px solid #ffffff33;
    padding-bottom: 20px;
      span:nth-child(2){
        color: #F80658;
      }
      }
    }
      .imgs {
        height: 48px;
        width: 48px;
        margin: 10px;
      }
      input {
        border: none;
        background: transparent;
        height: 56px;
        flex: 1;
      }
      .max {
        width: 100px;
        height: 50px;
        background: #262626;
        border-radius: 10px;
        margin: 10px;
        color: #fff;
        text-align: center;
        line-height: 50px;
        font-size: 22px;
      }
    }
    .ye {
      margin: 20px 34px;
      padding: 5px;
      font-size: 18px;
      white-space: nowrap;
      text-align: left;
      color: #ffffff;
      font-weight: 600;
      font-family: $t-f;
    }
    .btns {
      width: 638px;
      height: 80px;
      background: #000000;
      border-radius: 40px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 18px auto 20px;
    }
  }
  .list {
    width: 696px;
    min-height: 252px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0px auto;
    overflow: auto;
    .title {
      font-weight: 600;
      font-size: 28px;
      margin: 20px 32px;
      text-align: left;
    }
    .list-top {
      display: flex;
      align-items: left;
      justify-content: space-between;
      margin: 50px 0 0;
      border-top: 1px solid #f8f8f8;
      border-bottom: 1px solid #f8f8f8;
      padding: 30px 30px 30px;
      div {
        font-size: 24px;
        font-family: $t-mf;
        font-weight: 600;
        color: #8b8b93;
        line-height: 34px;
        text-align: left;
        white-space: nowrap;
        margin: 0 5px;
      }
    }
    .list-item {
      width: 100%;
      div {
        font-size: 24px;
        font-family: $t-f;
        font-weight: 400;
        color: #666666;
        line-height: 34px;
        text-align: left;
        // margin: 0 5px;
        white-space: nowrap;
        flex: 1;
        .fuzhi {
          background: transparent;
          border: none;
          width: 30px;
        }
        .fz {
          width: 28px;
          height: 26px;
          margin: 0 -5px;
        }
      }
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0px;
        border-bottom: 1px solid #f7f7fa;
        width: 100%;
      }
    }
    .d1 {
      width: 50%;
    }
    .d2 {
      width: 50%;
      text-align: right !important;
    }
  }

  .bottom {
    margin: 44px 0 24px;
    display: flex;
    align-items: center;
    .btn {
      // width: calc(100% - 48px);
      width: 100%;
      height: 80px;

    //   background: #61348b;
    background:  linear-gradient(48deg, #3C2F64, #CE0A54);
// box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.05), 0px 6px 12px 0px rgba(68,215,182,0.1);
      box-shadow: 0px 4px 6px 0px rgb(0,0,0);
        border: 1px solid #ffffff; 
      font-size: 32px;
      border-radius:0px ;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 20px auto;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .stbn {
      font-size: 30px;
      font-family: $t-f;
      font-weight: 600;
      color: #8b8b94;
      margin: 10px auto;
      line-height: 34px;
    }
  }
  .mentop {
    display: flex;
    justify-content: space-between;
    margin: 44px 30px;
    .logo {
      // height: 82px;
      width: 232px;
    }
    .gb {
      height: 40px;
      width: 40px;
      margin-right: 8px;
    }
  }
  .menulist {
    margin-top: 100px;
    .menuitem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 44px;
      border-bottom: 1px solid #f8f8f8;
      padding-bottom: 30px;
      .left {
        flex: 1;
        text-align: left;
        display: flex;
        img {
          height: 48px;
          width: 48px;
        }
        .txt {
          margin-left: 40px;
          font-size: 36px;
          font-family: $t-mf;
          font-weight: 600;
          @include font_color("font_color1");
          line-height: 50px;
        }
      }
      .right {
        img {
          height: 38px;
          width: 26px;
        }
      }
    }
  }
  .buttoms {
    height: 28px;
    font-size: 24px;
    font-family: $t-f;
    font-weight: bold;
    color: #999999;
    line-height: 28px;
    margin: 80px auto 0px;
    padding-bottom: 80px;
    width: 100%;
  }
  .card2{
        width: 652px;
    background: rgba(38, 38, 38, 0.48) url('../assets/img/bm/emit.png') no-repeat;
    //  box-shadow: 0px 4px 16px 4px rgba(62,1,162,1);
        background-size: contain ;
        background-position: center;
    border-radius: 20px;
    padding: 20px;
    min-height: 200px;
    margin: 5% auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .sitem{
         width: calc( 50% - 40px);
    text-align: center;
    background: rgba(0, 0, 0, 0.3411764705882353);
    margin: 10px;
    padding: 10px;
          .ttt{
               font-size: 24px;
        font-family: $t-mf;
        font-weight: 600;
        color:#888888;
        line-height: 40px;
        margin-top: 12px;

          }
          .num{
          margin-top: 22px;
        font-size: 26px;
        font-family: $t-f;
        font-weight: bold;
        background: linear-gradient(234deg,#e4005e, #7C58D2);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        white-space: nowrap;
          }
      }
  }
    .popup {
    width: 672px;
    background: #1B1D46;
    border-radius: 30px;
    ::v-deep.van-list{
      height: 50vh;
    overflow: auto;
    }
    // .title {
    //   font-size: 48px;
    //   font-family: $t-mf;
    //   font-weight: 600;
    //   color: #ffffff;
    //   line-height: 66px;
    //   margin: 44px auto;
    // }

    .bg {
      // background: linear-gradient(234deg, #e4005e, #7C58D2);
      // margin: 20px auto;
      padding: 15px;
      // width: 100%;
      height: 100%;
      // box-shadow: 0 10px 5px rgb(10 10 10 / 54%);
    }
    .box{
      min-height: auto;
    width: auto;
    border: 1px solid #ffffff33;
    // padding: 10px;
    // background: linear-gradient(8deg, #3C2F64, #CE0A54);
 .imgss{
      width: 100%;
      height: auto;
     }
    }
    .txt{ 
      margin: 10px 20px;
      font-size: 26px;
      text-align: left;
      color: #ffffff;
      }
    .flex {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      .act {
        // border: 5px solid #EF155D;
        // border-radius: 10px;
        color: #ffffff;
            background: rgb(25 0 22 / 51%);
    box-shadow: 2px 2px 0.04rem 0px #131418;
        // background:#0800195e;
        //  box-shadow:  inset 2px 2px 3px 0px #131418, inset -2px -2px 3px 0px #474853;
      }
    }
    .tips {
        flex-grow: 1;
        border: 5px solid transparent;
      font-size: 30px;
      font-family: $t-f;
      font-weight: 400;
      color: #333333;
      text-align: left;
      padding: 20px;
      background: rgb(255 255 255 / 100%);
      display: flex;
      align-items: center;
      overflow: hidden;
      flex-direction: column;
      margin: 20px 0;
    width: calc(100% - 38px);
    justify-content: flex-start;
    box-sizing: border-box;
    .stop{
         display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .sbottom{
      width: 100%;
      .lines{
        margin: 20px;
          display: flex;
      align-items: center;
      justify-content: space-between;
          border-bottom: 1px solid #ffffff33;
    padding-bottom: 20px;
      span:nth-child(2){
        color: #F80658;
      }
      }
    }
      .imgs {
        height: 48px;
        width: 48px;
        margin: 10px;
      }
      input {
        border: none;
        background: transparent;
        height: 56px;
        flex: 1;
      }
      .max {
        width: 100px;
        height: 50px;
        background: #262626;
        border-radius: 10px;
        margin: 10px;
        color: #fff;
        text-align: center;
        line-height: 50px;
        font-size: 22px;
      }
    }








    .xztype{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
       ::v-deep .van-stepper__input{
          font-size: 42px;
        }
        .tab{
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
              .tag{
       width: calc(30% - 20px);
       border: 3px solid #7C58D2;
       border-radius: 8px;
      font-size: 30px;
      font-family: $t-f;
      font-weight: 400;
      color: #ffffff;
      text-align: left;
      padding: 10px 10px;
      display: flex;
      background: #000;
      align-items: center;
      overflow: hidden;
      margin: 20px 10px 0;
      justify-content: center;
          }
           .act{
               background:linear-gradient(315deg, #7C58D2 0%, #7C58D2 100%);
        // border-radius: 10px;
        color: #ffffff;
        }
        }
        .item{
        width: calc(100% - 200px);
      font-size: 30px;
      font-family: $t-f;
      font-weight: 400;
       color: #ffffff;
      text-align: left;
      padding: 0px 10px;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin: 20px 10px 0;
      justify-content: center;
         white-space: nowrap;   
        }
      
    }
      .btn {
     width: calc(100% - 200px);
      height: 80px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 54px auto;
background:linear-gradient(48deg, #3C2F64, #CE0A54);
box-shadow: 0px 4px 16px 4px rgb(10, 10, 10);
  border: 1px solid #ffffff; 
border-radius: 0px;
  display: flex;
      align-items: center;
      justify-content: center;
    }
    }
    .card3{
      .level{
      font-size: 28px;
        font-family: $t-mf;
        font-weight: 600;
        color:#ffffff;
        line-height: 40px;
        margin-top: 12px; 
      }
      .lname{
        font-size: 24px;
        font-family: $t-mf;
        font-weight: 600;
        color:#888888;
        line-height: 40px;
        margin-top: 12px;
      }
    }
}

</style>